import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { checkAuth } from "../actions/auth";
import ClipLoader from "react-spinners/ClipLoader";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ( props ) => {
  const dispatch = useDispatch();
  const { authChecked, loggedIn } = useSelector(state => state.auth, shallowEqual);

  useEffect(() => {
    dispatch(checkAuth())
  }, [dispatch]);

  if (!authChecked) {
    return <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <ClipLoader />
      </div>;
  } else if (!loggedIn) {
    return (
      <Navigate to="/login" replace />
    );
  } else {
    return props.children;
  }
};

export default ProtectedRoute;
