import React from "react";
import { connect } from "react-redux";
import { logoutUser } from "../actions/auth";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";

const Logout = ({ dispatchLogoutUser }) => {
  const navigate = useNavigate()
  
  const handleClick = () => {
    dispatchLogoutUser().then(() => navigate("/"));
  };

  return (
    <div onClick={handleClick} style={{marginTop: 10, cursor: 'pointer'}}>
      <FontAwesomeIcon icon={faArrowRightFromBracket}/> Logout
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLogoutUser: () => dispatch(logoutUser())
  };
};

export default connect(null, mapDispatchToProps)(Logout);