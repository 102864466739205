import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import { list } from "../api";
import '../index.css';
import Table from "./Table";

const Home = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    list().then(results => {
      results.json().then(data => {
        setResults(data);
        setLoading(false);
      })
    })
  }, []);

  const columns = useMemo(
    () => [
      { Header: 'Name', accessor: 'name'}, 
      { Header: 'RIN', accessor: 'rin', 
        Cell: ({value, row}) => <a href={"/view/" + row.original.id}>{value}</a>
      },
      { Header: 'Active?', accessor: 'active',
        Cell: props => props.value ? <div><FontAwesomeIcon icon={faCheckCircle} style={{color: '#4AAF05'}} /></div> : <></>
      },
    ],
    []
  )
  
  if (loading)
    return <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <ClipLoader />
      </div>;

  return (
    <div style={{marginLeft: 70, marginTop: 20}}>
      <h3 style={{marginTop: 20, marginBottom: 20}}>
        <span style={{marginRight: 20}}>All Accounts</span>
        <Button className="blue-outline-btn" href="/new" style={{float: 'right', marginRight: 20}}>
          Create new account
        </Button>
      </h3>
      <Card style={{padding: '20px 10px', marginRight: 10, marginBottom: 20}}>
        <Table columns={columns} data={results} />
      </Card>
    </div>
  );
}

export default connect()(Home);