import { create } from "../api"
import { Button, ButtonGroup, Form, ToggleButton } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Create = (props) => {
  const [name, setName] = useState("");
  const [rin, setRin] = useState('');
  const [months, setMonths] = useState(1);

  const navigate = useNavigate()

  const handleCreate = (event) => {
    event.preventDefault()
    create({name, rin, months}).then((res) => {
      res.json().then((data) => {
        if (res.ok){
            toast.success("Account created successfully")
            setTimeout(() => {navigate(`/view/${data.id}`)}, 500)
        } else {
          toast.error("Error creating account. " + data.error)
        }
      })
    });
  }


  return (
    <div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: 150, width: 500}}>
      <h3>Create a new account</h3>
      <Form onSubmit={handleCreate}>
        <Form.Control className="mb-3"
          type="text"
          placeholder="Name"
          onChange={e => setName(e.target.value)}
        />
        <Form.Control className="mb-3"
          type="number"
          placeholder="Organization RIN"
          minLength={9}
          maxLength={9}
          onChange={e => setRin(e.target.value)}
        />
        <ButtonGroup className="mb-3">
          <div style={{marginRight: 20}}> Subscription Type: </div>
          <ToggleButton
            type="radio"
            id ="radio-0"
            name="radio"
            variant="outline-primary"
            value={1}
            checked={months == 1}
            onChange={(e) => setMonths(e.currentTarget.value)}>
            Monthly
          </ToggleButton>
          <ToggleButton
            id ="radio-1"
            type="radio"
            name="radio"
            variant="outline-primary"
            value={12}
            checked={months == 12}
            onChange={(e) => setMonths(e.currentTarget.value)}>
            Yearly
          </ToggleButton>
        </ButtonGroup>
        <Button style={{marginTop: 20, marginLeft: 200, width: 160}}
          disabled={rin.length !== 9} type="submit">
          Create
        </Button>
      </Form>
    </div>
  )
}

export default Create;