import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import ProtectedRoute from "./components/ProtectedRoute";
import 'bootstrap/dist/css/bootstrap.min.css';
import Topbar from "./components/Topbar";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Create from "./components/Create";
import View from "./components/View";

function App() {
  return (
    <div className='App'>
      <ToastContainer />
      <Router>
        <Routes>
          <Route exact path='/' element={<ProtectedRoute>
            <Topbar />
            <Home />
          </ProtectedRoute>} />
          <Route exact path='/new' element={<ProtectedRoute>
            <Topbar />
            <Create />
          </ProtectedRoute>} />
          <Route exact path='/view/:id' element={<ProtectedRoute>
            <Topbar />
            <View />
          </ProtectedRoute>} />
          <Route exact path='/login' element={<Login />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;