import React from "react";
import { Container, Navbar, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { checkAuth } from "../actions/auth";
import Logout from "./Logout";
import logoWhite from '../assets/logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";


const Topbar = (props) => {
  const { currentUser } = props;
  return (
    <Navbar sticky="top" style={{backgroundColor: '#6F90BD'}}>
      <Container>
        <Navbar.Brand href="/"><img src={logoWhite} alt='logo'/></Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <Row style={{textAlign: 'right', width: 200, color: 'white'}}>
              <div><FontAwesomeIcon icon={faCircleUser}/></div>
              <div>{currentUser.email}</div>
              <div>{currentUser.reseller?.name}</div>
              <Logout />
            </Row>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

const mapStateToProps = ({ auth: { authChecked, loggedIn, currentUser } }) => {
  return { authChecked, loggedIn, currentUser };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchCheckAuth: () => dispatch(checkAuth())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);