import { update, view } from "../api"
import { Button, ButtonGroup, Col, Form, Row, ToggleButton } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const View = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [autoRenew, setAutoRenew] = useState(false);
  const [months, setMonths] = useState(1);

  let { id } = useParams();

  useEffect(() => {
    view(id).then(results => {
      results.json().then(data => {
        setData(data);
        setAutoRenew(data.auto_renew);
        setMonths(data.months);
        setLoading(false);
      })
    })
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    update(id, { organization: {active: true, auto_renew: autoRenew, months} }).then(res => {
      if (res.ok) {
        toast.success("Account updated successfully");
        view(id).then(results => {
          results.json().then(data => {
            setData(data);
            setAutoRenew(data.auto_renew);
            setMonths(data.months);
          })
        })
      }
    })
  };
  
  if (loading)
    return <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <ClipLoader />
      </div>;

  return (
    <div style={{marginLeft: 70, marginTop: 20}}>
      <Button className="blue-outline-btn" href="/">
        Back to list
      </Button>
      <h3 style={{textAlign: 'center', marginBottom: 20}}>
        <span style={{marginRight: 20}}>{data.name}</span> {data.rin}
      </h3>
      <h3>
        <span style={{marginRight: 20}}>Subscription {data.active ? 
          <span>Active <FontAwesomeIcon icon={faCheckCircle} style={{color: '#4AAF05'}}/></span> : 
          <span>Inctive <FontAwesomeIcon icon={faTimesCircle} style={{color: '#FF0000'}}/></span>}  
        </span>
      </h3>
      
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Check className="mb-3" type="switch" label="Auto Renew" checked={autoRenew} onChange={(e) => setAutoRenew(e.currentTarget.checked)}/>
          </Col>
          <Col>
            <div> Next renewal data: {data.next_payment_date.substr(0, 10)}</div>
          </Col>
        </Row>
        
        <ButtonGroup>
          <div style={{marginRight: 20}}> Subscription Type: </div>
          <ToggleButton
            type="radio"
            id ="radio-0"
            name="radio"
            variant="outline-primary"
            value={1}
            checked={months == 1}
            onChange={(e) => setMonths(e.currentTarget.value)}>
            Monthly
          </ToggleButton>
          <ToggleButton
            id ="radio-1"
            type="radio"
            name="radio"
            variant="outline-primary"
            value={12}
            checked={months == 12}
            onChange={(e) => setMonths(e.currentTarget.value)}>
            Yearly
          </ToggleButton>
      </ButtonGroup>
      <br/>
        <Button style={{margin: 50}} type="submit">
          {data.active ? "Update" : "Activate"}
        </Button>
      </Form>
      
    </div>
  );
}

export default View;