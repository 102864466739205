import { backend_url } from "./config"

export const create = (data) => {
  return fetch(`${backend_url}/organizations`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
    }
  })
}

export const list = () => {
  return fetch(`${backend_url}/organizations`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
    }
  })
}

export const view = (id) => {
  return fetch(`${backend_url}/organizations/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
    }
  })
}

export const update = (id, data) => {
  return fetch(`${backend_url}/organizations/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")
    }
  })
}
