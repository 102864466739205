import React from "react";
import { connect } from "react-redux";
import { loginUser } from "../actions/auth";
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import { Button, Card, Form } from "react-bootstrap";

const Login = (props) => {
  const navigate = useNavigate()
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    props
      .dispatchLoginUser({ email, password })
      .then(() => navigate("/"))
      .catch(() => setError(true));
  };

  return (
    <div style={{backgroundColor: '#FFF8F6', textAlign: 'center', paddingTop: 100}}>
      <img src={logo} alt="logo" style={{width: '320px'}}/>
      <Card style={{width: 450, marginLeft: 'auto', marginRight: 'auto', marginTop: 100}}>
      <Card.Body>
      <Form onSubmit={handleSubmit} style={{textAlign: 'left', paddingLeft: 80, paddingTop: 60, paddingRight: 80, paddingBottom: 60}}>
        <h3 style={{marginBottom: 0}}>Welcome</h3>
        <span style={{color: '#BDBDBD'}} >Login to your account</span>
        <p className="h-8 text-red-400">{error && "Invalid email or password"}</p>
        <Form.Control type="email" name='email' id='email' 
          placeholder="Email" style={{marginTop: 20}}
          onChange={e => setEmail(e.target.value)}
          value={email} />
        <Form.Control type="password" placeholder="Password" name='password'
            id='password' style={{marginTop: 20}}
            onChange={ e => setPassword(e.target.value)}
            value={password}/>

        <Button variant="primary" type="submit" style={{marginTop: 20}} className="teal-btn">
            Sign in
        </Button>
      </Form>
      </Card.Body>
      </Card>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLoginUser: (credentials) => dispatch(loginUser(credentials))
  };
};

export default connect(null, mapDispatchToProps)(Login);